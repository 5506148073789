import { BillingType } from 'store/users/users.type';
import { TimeRanges } from '../store/appointmentGroups/type';

export const DEFAULT_USER_TIMEZONE = 'America/Chicago';
export const TABLE_HEADER_HEIGHT = 56;
export const TABLE_ROW_HEIGHT = 52;

export const DEFAULT_DEBOUNCE_TIME_SEC = 250;

export const DEFAULT_MAX_LENGTH_TEXT = 250;

export const MAX_AVAILABLE_HOUR = 21;

export const ADD_BOOKING_FORM_FIELD_CLEANED = {
  additionalInformation: 'Answers to additional questions',
  addonIds: 'Addon',
  appointmentId: 'Appointment',
  assignedManagerId: 'Scheduling manager',
  clientId: 'Customer',
  creditCardId: 'Credit Card',
  customDays: 'Custom Days',
  industryId: 'Industry',
  internalNote: 'Internal note',
  price: 'Price',
  requestedDate: 'Requested Date',
  requestedStaffIds: 'Requested Staff',
  requestedTime: 'Requested Time',
  recurrenceEndDate: 'End Date',
  variantId: 'Variant',
  deposit: 'Request Deposit',
};

export const DEFAULT_TIME_SLOTS = [
  {
    key: TimeRanges.EarlyMorning,
    startTimeMin: 300,
    endTimeMin: 540,
  },
  {
    key: TimeRanges.Morning,
    startTimeMin: 540,
    endTimeMin: 720,
  },
  {
    key: TimeRanges.Midday,
    startTimeMin: 720,
    endTimeMin: 900,
  },
  {
    key: TimeRanges.Afternoon,
    startTimeMin: 900,
    endTimeMin: 1080,
  },
  {
    key: TimeRanges.Evening,
    startTimeMin: 1080,
    endTimeMin: 1260,
  },
];

export const BILLING_TYPE_ICON_MAP = {
  [BillingType.PerHour]: '$',
  [BillingType.PerService]: '%',
};

export const BILLING_TYPE_TEXT_MAP = {
  [BillingType.PerHour]: 'per hour',
  [BillingType.PerService]: 'per service',
};

export const CUSTOM_LABEL = 'MuiCustomLabel';
export const CUSTOM_FORM_LABEL = 'MuiCustomFormLabel';
